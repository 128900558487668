<template>
<div v-if="$parent.user != null" class="user" style="padding-top: 50px; padding-bottom:50px; height: 100%">
    <vs-dialog prevent-close blur v-model="modalChooseProduct">
        <template #header>
            <h3 class="not-margin" style="font-weight: 100; margin-bottom: 0;">
                Crea un <b>nuovo</b> progetto.
            </h3>
        </template>

        <ProductList :products="products" :no-drafts="true"/>

    </vs-dialog>
    
    <vs-row justify="center" style="margin-top: 40px; height: 100%;" >
            
            
            <vs-row direction="column" style="background: white;padding: 20px;border-radius: 21px; gap: 20px; width: 69%; height: 100%; padding-top: 50px; overflow:hidden;">
                <vs-navbar square  v-model="productId">
                    <template #left>
                    <vs-navbar-item id="-1" :active="productId == -1">
                    Tutti i progetti
                    </vs-navbar-item>
                    
                    <vs-navbar-item v-for="p in products" :key="'product-'+p.id" :active="productId == p.id" :id="p.id">
                    {{ p.title }}
                    </vs-navbar-item>
                    
                    </template>
                    
                </vs-navbar>

                <vs-row justify="space-between" align="center">
                    <h2>I miei progetti</h2>
                    <vs-button size="large" color="primary"  class="animate"  icon @click="modalChooseProduct = true">
                        <i class='bx bx-plus bx-sm' style="margin-right: 5px;"></i> Nuovo 
                    </vs-button>
                </vs-row>

                
                <vs-row justify="space-between" align="center" v-for="o in userOrders" :key="'order-' + o.id">
                    <vs-row align="center" justify="flex-start" style="width: 70%;" class="order-info">
                        <img class="product-preview" :src="o.product.imageUrl" style="border-radius: 15px; max-height: 150px; max-width: 150px; margin-right: 20px;" />
                        <vs-row direction="column" style="width: 70%;" >
                            <p><b>Codice:</b> {{ o.id }}</p>
                            <p><b>Stato:</b>
                                <template v-if="o.invoices[0] == null">
                                    Bozza
                                </template>
                                <template v-else-if="o.invoices[0].status == 'PENDING'">
                                    In attesa di pagamento
                                </template>
                                <template v-else-if="o.invoices[0].status == 'EXPIRED'">
                                    Mancato pagamento
                                </template>
                                <template v-else-if="o.invoices[0].status == 'PAID'">
                                    <template v-if="o.isReady">
                                        Elaborazione file completata
                                    </template>
                                    <template v-else>
                                        Elaborazione file in corso.
                                    </template>
                                </template>
                            </p>
                            <p><b>Articolo:</b> {{ o.product.title }}</p>
                            <p><b>Ultima modifica:</b> {{ (new Date(o.updatedAt)).toLocaleDateString() }} {{ (new Date(o.updatedAt)).toLocaleTimeString() }}</p>

                        </vs-row>
                    </vs-row>
                    <vs-row direction="column" style="width: 20%;" class="order-buttons">
                        <vs-row>
                            <vs-button size="large" transparent block icon :disabled="(o.invoices[0].status != 'PENDING' || o.invoices[0].stripeInvoiceUrl == null)" @click="openOrderInvoice(o)" v-if="o.invoices[0]">
                                <i class='bx bxs-credit-card bx-sm'></i>

                                <template v-if="o.invoices[0].status == 'PENDING'">
                                    <template v-if="o.invoices[0].stripeInvoiceUrl != null">
                                        Paga ora
                                    </template>
                                    <template v-else>
                                        Pagamento non disp.
                                    </template>
                                </template>

                                <template v-else-if="o.invoices[0].status == 'EXPIRED'">
                                    Scaduto
                                </template>
                                <template v-else-if="o.invoices[0].status == 'PAID'">
                                    Pagato
                                </template>
                            </vs-button>
                            <vs-button v-else size="large"  block icon @click="openDraft(o)">
                                <i class='bx bxs-layout bx-sm' style="margin-right: 5px;"></i> Riprendi il progetto
                            </vs-button>
                            
                        </vs-row>
                    </vs-row>
                </vs-row>
            </vs-row>


    </vs-row>
</div>
</template>

<script>
import ProductList from '../components/ProductList';
import {
    apiCall,
} from '../client';
import {
    s3Client
} from '../s3Client';
export default {
    props: {
    },
    components: {
        ProductList
    },
    data() {
        return {
            userOrders: [],
            
            currentPage: 0,
            orderFinished: false,
            orderLoading: false,

            modalChooseProduct: false,

            products: [],
            productId: -1,

        }
    },
    mounted() {
        if(this.$route.query.productId != null){
            this.productId = parseInt(this.$route.query.productId);
        }

        this.getProductList();
        this.getOrders();

        var vm = this;

        window.onscroll = () => {
            let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;

            if (bottomOfWindow) {
                if (!vm.orderFinished && !vm.orderLoading) { // non ho finito le pagine e non sta caricando
                    vm.currentPage++;
                }
            }
        };
    },

    watch: {
        '$parent.user'(n) {
            this.user = JSON.parse(JSON.stringify(n));
        },

        currentPage() {
            this.getOrders();
        },

        productId() {
            this.userOrders = [];
            this.currentPage = 0;
            this.getOrders();
        }
    },

    methods: {
        openDraft(o) {
            this.$router.push('/store/' + o.product.id + '/' + o.id);
        },
        
        async getProductList() {
            var result = await apiCall('GET', '/Product');
            if (result.status == 200) {
                result.data = await Promise.all(result.data.map(async (o) => {
                    const signedUrl = await s3Client.getSignedDownloadURL({name: o.fileId});
                    o.previewFileUrl = signedUrl.url;

                    return o;
                }));
                this.products = result.data;
            }
        },

        async getOrders() {
            this.orderLoading = true;
            var result = await apiCall('GET', '/Order', {
                page: this.currentPage,
                type: 'Draft',
                productId: this.productId == -1 ? undefined : this.productId
            });
            if (result.status == 200) {
                if (result.data.length > 0) {
                    this.orderFinished = false;
                } else {
                    this.orderFinished = true;
                }

                result.data = await Promise.all(result.data.map(async (o) => {
                    const signedUrl = await s3Client.getSignedDownloadURL({name: o.product.fileId});
                    o.product.imageUrl = signedUrl.url;

                    return o;
                }));

                this.userOrders = this.userOrders.concat(result.data);
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile ottenere l'elenco degli ordini.`
                })
            }
            this.orderLoading = false;
        },
    }
}
</script>

<style>
.user .vs-avatar__badge {
    width: 32px;
    height: 32px;
    border-radius: 10px;
}

.user .vs-avatar {
    font-size: 4rem;
}

.propic-mobile {
    display: none;
}

/* MOBILE */
@media (max-width: 1023px) {
    .user .user-columns {
        grid-template-columns: 100% !important;
        width: 85% !important;
    }

    .user {
        padding-top: 0 !important;
    }

    .user .sx-column {
        display: none;
    }

    .user .vs-col {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-top: 32px;
    }

    .propic-mobile {
        display: flex;
        margin-bottom: 20px;
    }

    img.product-preview {
        width: 100% !important;
        height: auto !important;
    }

    .order-info,
    .order-buttons {
        width: 100% !important;
    }
}
</style>
